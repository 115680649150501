<template>
  <label v-bind="$attrs" class="label" :class="{ up: moveToUp }" @click="$emit('click')">{{ label }}</label>
</template>

<script>
export default {
  name: 'PaymentMethodFormLabel',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      required: true,
    },
    moveToUp: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <div class="payment-details" :class="{ 'payment-details--with-form': isShowAddPaymentMethodForm }">
    <BackHeader is-show-title is-static title="Payment details" />

    <div class="payment-details__content">
      <section class="membership-base-info">
        <h3 class="membership-subheader">
          Your membership level <span class="membership-name">{{ membershipTypeHeader }}</span>
        </h3>
        <p v-if="museumRole" class="membership-price">{{ membershipPriceText }}</p>
      </section>

      <section v-if="museumRole && !isUserMembershipLapsed" class="membership-adjust">
        <button
          v-if="!subscriptionCancelAt"
          class="membership-subheader membership-adjust__btn"
          type="button"
          @click="toggleMembershipAdjustOptions"
        >
          Adjust your membership
        </button>
        <p v-else class="membership-cancelled-text">
          Your membership has been cancelled and will end on {{ subscriptionCancelAt }} (EST)
        </p>

        <CollapseTransition :duration="200">
          <form
            v-if="showMembershipAdjustOptions"
            class="membership-adjust-options-wrapper"
            @submit.prevent="handleSubmitMembershipAdjust"
          >
            <ul class="membership-adjust-options">
              <li v-if="membership.subscription.is_monthly" class="membership-adjust-options__item">
                <label class="membership-adjust-option">
                  <input
                    v-model="membershipAdjustSelected"
                    type="radio"
                    :value="membershipAdjustOptions.upgradeToAnnual"
                  />
                  <span class="membership-adjust-option__icon"></span>
                  <span class="membership-adjust-option__name">Upgrade to annual membership</span>
                </label>
                <div class="membership-adjust-option__info">
                  <p>Receive a physical museum card</p>
                  <p>1 year prepaid - {{ membership.upgrade_to_annual_prices }}</p>
                </div>
              </li>
              <li class="membership-adjust-options__item">
                <label class="membership-adjust-option">
                  <input
                    v-model="membershipAdjustSelected"
                    type="radio"
                    :value="membershipAdjustOptions.upgradeToFullMember"
                  />
                  <span class="membership-adjust-option__icon"></span>
                  <span class="membership-adjust-option__name">Inquire about upgrading to Club membership</span>
                </label>
              </li>
              <li class="membership-adjust-options__item">
                <label class="membership-adjust-option">
                  <input v-model="membershipAdjustSelected" type="radio" :value="membershipAdjustOptions.cancel" />
                  <span class="membership-adjust-option__icon"></span>
                  <span class="membership-adjust-option__name">Cancel your membership</span>
                </label>
                <div class="membership-adjust-option__info">
                  <p>
                    Please note, by taking this action you will not be charged upon renewal and you can continue using
                    your online account and benefits until your membership expires.
                  </p>
                </div>
              </li>
            </ul>

            <div class="membership-update-actions">
              <button
                type="submit"
                class="btn border-btn payment-details-form-button"
                :disabled="!membershipAdjustSelected || isFormRequestSending"
              >
                <AppLoader v-if="isFormRequestSending" :border-width="3" :margin="0" :size="20" />
                <span v-else>Save</span>
              </button>
            </div>
          </form>
        </CollapseTransition>
      </section>

      <section class="member-cards">
        <h3 class="membership-subheader">Cards</h3>
        <PaymentMethods
          v-if="paymentMethods.length"
          :payment-methods="paymentMethods"
          :changing-default-id="selectedPaymentMethodAsDefault"
          :deleting-id="deletingPaymentMethodId"
          :disable-buttons="isFormRequestSending"
          :show-loader-on-deleting="!isShowConfirmDeleteModal"
          @changing-default="setDefaultPaymentMethod"
          @deleting="confirmDeletingPaymentMethod"
        />
        <p v-else-if="!loadingPaymentMethods" class="member-cards__list-empty-msg">No card has been added yet.</p>
        <div v-else class="my-3">
          <AppLoader :border-width="3" :size="28" />
        </div>
      </section>

      <div class="add-card-form-wrapper">
        <button class="add-card-form-wrapper__btn-form-toggler" @click="toggleAddPaymentMethodForm">
          Add new card
        </button>

        <CollapseTransition
          :duration="200"
          @enter="beforeSlideDown"
          @after-enter="afterSlideDown"
          @before-leave="beforeSlideDown"
          @after-leave="afterSlideDown"
        >
          <AddNewPaymentMethodForm
            v-show="isShowAddPaymentMethodForm"
            class="add-card-form"
            :init-user-data="initUserFormData"
            :show="isShowAddPaymentMethodForm"
            :stripe-public-key="stripePublicKey"
            @added="handleAddedNewPaymentMethod"
          />
        </CollapseTransition>
      </div>
    </div>

    <Transition name="slide-up">
      <AppConfirmationModal
        v-if="isShowConfirmDeleteModal"
        :show="isShowConfirmDeleteModal"
        :header="deletingModalHeader"
        @close="closeConfirmDeleteModal"
      />
    </Transition>

    <Transition name="slide-up">
      <AppInfoModal
        v-if="isShowInfoModal"
        :show="isShowInfoModal"
        :message="infoModalMessage"
        @close="closeInfoModal"
      />
    </Transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { findCountry } from '@/models/countriesList';

import meMembershipQuery from '@/graphql/me/membership/MeMembership.query.gql';
import mePaymentDetailsQuery from '@/graphql/me/payment/MePaymentDetails.query.gql';
import changeDefaultPaymentMethodMutation from '@/graphql/me/payment/ChangeDefaultPaymentMethod.mutation.gql';
import deletePaymentMethodMutation from '@/graphql/me/payment/DeletePaymentMethod.mutation.gql';
import updateBillingAddressMutation from '@/graphql/me/payment/UpdateUserBillingAddress.mutation.gql';
import cancelMembershipMutation from '@/graphql/me/membership/CancelMembership.mutation.gql';
import sendUserMembershipUpgradeRequestMutation from '@/graphql/me/membership/SendUserMembershipUpgradeRequest.mutation.gql';
import upgradeToAnnualMembershipMutation from '@/graphql/me/membership/UpgradeToAnnualMembership.mutation.gql';

import AppConfirmationModal from '@/components/modals/AppConfirmationModal';
import BackHeader from '@/components/partials/BackHeader';
import PaymentMethods from '@/components/partials/profile/PaymentMethods';
import CollapseTransition from '@/components/transitions/CollapseTransition';
import AddNewPaymentMethodForm from '@/components/partials/profile/AddNewPaymentMethodForm';
import AppInfoModal from '@/components/modals/AppInfoModal';

export default {
  name: 'PaymentDetails',
  components: {
    AppInfoModal,
    AddNewPaymentMethodForm,
    CollapseTransition,
    AppConfirmationModal,
    BackHeader,
    PaymentMethods,
  },
  metaInfo: {
    title: 'Payment details',
  },
  data() {
    return {
      showMembershipAdjustOptions: false,
      membershipAdjustOptions: {
        upgradeToAnnual: 'upgrade_to_annual',
        upgradeToFullMember: 'upgrade_to_full_member',
        cancel: 'cancel',
      },
      membershipAdjustSelected: null,

      paymentMethods: [],
      selectedPaymentMethodAsDefault: null,
      deletingPaymentMethodId: null,
      deletingModalHeader: '',

      loadingPaymentMethods: true,
      isFormRequestSending: false,
      isShowAddPaymentMethodForm: false,
      addingNewPaymentMethod: false,

      stripePublicKey: '',

      initUserFormData: {
        billingDetails: {
          email: '',
          name: '',
          address: {
            city: '',
            country: '',
            line1: '',
            line2: '',
            postal_code: '',
            state: '',
          },
        },
        selectedCountry: null,
      },

      isShowConfirmDeleteModal: false,
      closeConfirmDeleteModalCallback: () => {},

      isShowInfoModal: false,
      infoModalMessage: '',
      closeInfoModalCallback: () => {},
    };
  },
  computed: {
    ...mapState(['membership', 'museumRole']),
    ...mapGetters(['isUserMembershipLapsed']),
    membershipTypeHeader() {
      return this.membership.type.replace(/member/i, '') + ' Member';
    },
    membershipPriceText() {
      let text = this.membership.subscription.price_for_human;
      if (this.membership.subscription.price_hint) {
        text += ` / ${this.membership.subscription.price_hint}`;
      }

      return text;
    },
    subscriptionCancelAt() {
      return this.membership.subscription.cancel_at_formatted;
    },
  },
  watch: {
    subscriptionCancelAt(val) {
      if (val) {
        this.showMembershipAdjustOptions = false;
      }
    },
  },

  async created() {
    this.fetchMembershipSubscription();
  },

  async mounted() {
    const { email, name, address, publicKey, paymentMethods, defaultPaymentMethod } = await this.getPaymentDetails();
    this.stripePublicKey = publicKey;
    this.setInitUserFormData({ email, name, address });

    if (paymentMethods) {
      this.paymentMethods = paymentMethods.map((pm) => {
        const isDefault = pm.id === defaultPaymentMethod;
        return { ...pm, isDefault };
      });
    }
    this.loadingPaymentMethods = false;
  },
  methods: {
    handleSubmitMembershipAdjust() {
      if (!this.membershipAdjustSelected || this.isFormRequestSending) {
        return;
      }

      if (this.membershipAdjustSelected === this.membershipAdjustOptions.upgradeToAnnual) {
        this.upgradeToAnnualMembership();
        return;
      }

      if (this.membershipAdjustSelected === this.membershipAdjustOptions.upgradeToFullMember) {
        this.sendMembershipUpgradeRequest();
        return;
      }

      if (this.membershipAdjustSelected === this.membershipAdjustOptions.cancel) {
        this.deletingModalHeader = 'Cancel your membership';
        this.closeConfirmDeleteModalCallback = this.closeModalCallbackToCancelMembership;
        this.isShowConfirmDeleteModal = true;
      }
    },

    beforeSlideDown(el) {
      el.style.height = el.scrollHeight + 'px';
    },
    afterSlideDown(el) {
      el.style.height = '';
    },

    async fetchMembershipSubscription() {
      const { data } = await this.$apollo.query({ query: meMembershipQuery });
      this.$store.dispatch('setUserMembership', { ...data.me.membership });
    },

    async getPaymentDetails() {
      const result = await this.$apollo.query({ query: mePaymentDetailsQuery });
      const { email, name, address, publicKey, paymentMethods, defaultPaymentMethod } = result.data.mePaymentDetails;

      return { email, name, address, publicKey, paymentMethods, defaultPaymentMethod };
    },

    setInitUserFormData({ email, name, address }) {
      this.initUserFormData.billingDetails.email = email;
      this.initUserFormData.billingDetails.name = name;

      if (address) {
        this.initUserFormData.billingDetails.address.city = address.city;
        this.initUserFormData.billingDetails.address.line1 = address.address_line_1;
        this.initUserFormData.billingDetails.address.line2 = address.address_line_2 || '';
        this.initUserFormData.billingDetails.address.postal_code = address.postal_code;
        this.initUserFormData.billingDetails.address.state = address.state;

        const country = findCountry(address.country, 'name');
        if (country) {
          this.initUserFormData.selectedCountry = {
            code: country.code,
            name: country.name,
          };
          this.initUserFormData.billingDetails.address.country = this.initUserFormData.selectedCountry.code;
        }
      }
    },

    toggleAddPaymentMethodForm() {
      this.isShowAddPaymentMethodForm = !this.isShowAddPaymentMethodForm;

      if (this.isShowAddPaymentMethodForm) {
        this.showMembershipAdjustOptions = false;
        this.membershipAdjustSelected = null;
      }
    },
    toggleMembershipAdjustOptions() {
      this.showMembershipAdjustOptions = !this.showMembershipAdjustOptions;
      if (this.showMembershipAdjustOptions) {
        this.isShowAddPaymentMethodForm = false;
      } else {
        this.membershipAdjustSelected = null;
      }
    },

    async handleAddedNewPaymentMethod(paymentMethodId) {
      this.isShowAddPaymentMethodForm = false;
      this.isFormRequestSending = true;

      const defaultPaymentMethod = await this.changeDefaultPaymentMethod(paymentMethodId);
      if (defaultPaymentMethod) {
        this.addPaymentMethodToCollection(defaultPaymentMethod);
        this.resetPaymentMethodsDefaultStatus(defaultPaymentMethod);
        await this.updateBillingAddress(defaultPaymentMethod);
      }
      this.isFormRequestSending = false;
    },

    addPaymentMethodToCollection(paymentMethod) {
      this.paymentMethods.unshift({ ...paymentMethod, isDefault: false });
    },

    async setDefaultPaymentMethod(id) {
      if (this.isFormRequestSending) {
        return;
      }
      this.isFormRequestSending = true;

      const defaultPaymentMethod = await this.changeDefaultPaymentMethod(id);
      if (defaultPaymentMethod) {
        await this.updateBillingAddress(defaultPaymentMethod);
        this.resetPaymentMethodsDefaultStatus(defaultPaymentMethod);
      }
      this.isFormRequestSending = false;
    },
    async changeDefaultPaymentMethod(id) {
      this.selectedPaymentMethodAsDefault = id;

      const result = await this.$apollo.mutate({
        mutation: changeDefaultPaymentMethodMutation,
        variables: { id },
      });
      return result?.data?.changeDefaultPaymentMethod;
    },
    resetPaymentMethodsDefaultStatus(defaultPaymentMethod) {
      this.paymentMethods = this.paymentMethods
        .map((pm) => {
          pm.isDefault = pm.id === defaultPaymentMethod.id;
          return pm;
        })
        .sort((a, b) => {
          return a.isDefault === b.isDefault ? 0 : a.isDefault ? -1 : 1;
        });
      this.selectedPaymentMethodAsDefault = null;
    },

    confirmDeletingPaymentMethod(paymentMethod) {
      if (this.isFormRequestSending) {
        return;
      }
      this.deletingPaymentMethodId = paymentMethod.id;
      this.deletingModalHeader = `Delete the card (${paymentMethod.card.last4})`;
      this.closeConfirmDeleteModalCallback = this.closeModalCallbackToDeletePaymentMethod;
      this.isShowConfirmDeleteModal = true;
    },

    async closeConfirmDeleteModal(answer) {
      this.isShowConfirmDeleteModal = false;
      await this.closeConfirmDeleteModalCallback(answer);
      this.closeConfirmDeleteModalCallback = () => {};
    },
    async closeModalCallbackToDeletePaymentMethod(answer) {
      if (!answer) {
        this.deletingPaymentMethodId = null;
        return;
      }
      if (this.isFormRequestSending) {
        return;
      }

      this.isFormRequestSending = true;
      await this.deletePaymentMethod();
      this.isFormRequestSending = false;
    },
    async deletePaymentMethod() {
      if (!this.deletingPaymentMethodId) {
        return false;
      }

      const result = await this.$apollo.mutate({
        mutation: deletePaymentMethodMutation,
        variables: {
          id: this.deletingPaymentMethodId,
        },
      });

      if (!result?.data?.deletePaymentMethod.id) {
        return false;
      }

      if (this.paymentMethods.length - 1 === 0) {
        await this.updateBillingAddress();
      }

      const deletedIndex = this.paymentMethods.findIndex((pm) => {
        return pm.id === this.deletingPaymentMethodId;
      });
      this.paymentMethods.splice(deletedIndex, 1);

      this.deletingPaymentMethodId = null;

      return true;
    },

    async updateBillingAddress(paymentMethod = {}) {
      const result = await this.$apollo.mutate({
        mutation: updateBillingAddressMutation,
        variables: {
          ...this.preparedBillingAddress(paymentMethod),
        },
      });
      return result?.data?.updateUserBillingAddress;
    },
    preparedBillingAddress(paymentMethod = {}) {
      const address = paymentMethod?.billing_details?.address || {};
      let addressCountry = address.country || null;
      if (addressCountry) {
        const country = findCountry(addressCountry, 'code');
        addressCountry = country ? country.name : addressCountry;
      }

      return {
        country: addressCountry,
        city: address.city || null,
        addressLine1: address.line1 || null,
        addressLine2: address.line2 || null,
        postalCode: address.postal_code || null,
        state: address.state || null,
      };
    },

    async upgradeToAnnualMembership() {
      try {
        this.isFormRequestSending = true;
        const { data } = await this.$apollo.mutate({
          mutation: upgradeToAnnualMembershipMutation,
        });

        this.$store.dispatch('setUserMembership', data.upgradeToAnnualMembership.data);

        this.infoModalMessage = data.upgradeToAnnualMembership.message;
        this.isShowInfoModal = true;
        this.membershipAdjustSelected = null;
      } catch (e) {
        this.membershipAdjustSelected = null;
        this.$toast.error(e.graphQLErrors[0].message);
      } finally {
        this.isFormRequestSending = false;
      }
    },

    async sendMembershipUpgradeRequest() {
      try {
        this.isFormRequestSending = true;
        const { data } = await this.$apollo.mutate({
          mutation: sendUserMembershipUpgradeRequestMutation,
        });
        this.infoModalMessage = data.sendUserMembershipUpgradeRequest.message;
        this.isShowInfoModal = true;
        this.membershipAdjustSelected = null;
      } catch (e) {
        this.membershipAdjustSelected = null;
        this.$toast.error(e.graphQLErrors[0].message);
      } finally {
        this.isFormRequestSending = false;
      }
    },

    async closeInfoModal() {
      this.isShowInfoModal = false;
      this.isFormRequestSending = true;
      await this.closeInfoModalCallback();

      this.isFormRequestSending = false;
      this.closeInfoModalCallback = () => {};
    },
    async closeModalCallbackToCancelMembership(answer) {
      if (answer) {
        await this.cancelMembership();
      }
    },
    async cancelMembership() {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: cancelMembershipMutation,
        });
        this.infoModalMessage = data.cancelUserMembership.message;
        this.membershipAdjustSelected = null;

        if (data.cancelUserMembership.data.subscription.is_cancelled) {
          this.closeInfoModalCallback = this.logoutAfterCancellation;
        } else {
          this.$store.dispatch('setUserMembership', data.cancelUserMembership.data);
        }

        this.isShowInfoModal = true;
      } catch (e) {
        this.membershipAdjustSelected = null;
        this.$toast.error(e.graphQLErrors[0].message);
      }
    },
    logoutAfterCancellation() {
      this.$store.dispatch('logout', { notSendRequest: true }).then(() => {
        this.$router.push({ name: 'login' });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.membership-base-info {
  padding-top: 7px;
}

.membership-subheader {
  font-size: 16px;
  letter-spacing: 0.18em;
  color: #999;
  text-transform: uppercase;
}

.membership-cancelled-text {
  margin-bottom: 25px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.05em;
  color: #999;
}

.membership-name {
  display: block;
  margin-top: 12px;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.18em;
  color: #363636;
}

.membership-price {
  font-size: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.membership-adjust {
  margin-top: 45px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(53, 53, 53, 0.2);

  &__btn {
    margin-bottom: 25px;
    padding-left: 0;
  }

  &-options {
    &-wrapper {
      padding-bottom: 20px;
    }

    list-style-type: none;
    padding-left: 0;

    &__item {
      &:not(:first-child) {
        margin-top: 30px;
      }
    }
  }

  &-option {
    display: flex;
    max-width: max-content;
    margin-bottom: 0;
    padding-right: 10px;

    input {
      position: absolute;
      width: 1px;
      height: 1px;
      margin: -1px;
      padding: 0;
      overflow: hidden;
      clip: rect(0 0 0 0);
      color: transparent;
    }

    &__icon {
      display: block;
      width: 15px;
      height: 15px;
      margin-top: 4px;
      border: 1px solid #363636;
      border-radius: 100%;
    }

    input:checked ~ &__icon {
      background-color: #363636;
    }

    &__name {
      flex: 1;
      margin-left: 10px;
      font-size: 16px;
      letter-spacing: 0.18em;
      color: #363636;
      text-transform: uppercase;
    }

    &__info {
      margin-top: 7px;
      padding-left: 24px;
      font-size: 15px;
      letter-spacing: 0.05em;
      color: #999;

      p {
        margin-bottom: 7px;
      }
    }
  }
}

.membership-update-actions {
  margin-top: 50px;
  text-align: center;

  @media (min-width: 768px) {
    text-align: right;
  }
}
</style>

<template>
  <form class="add-card-form" @submit.prevent="submitFormToCreatePaymentMethod">
    <div class="row position-relative">
      <div class="col-12 col-lg-6">
        <div class="add-card-form__input-container">
          <payment-method-form-label
            :label="cardElements.cardNumber.name"
            :move-to-up="cardElements.cardNumber.isNotEmpty"
          />
          <div class="d-block">
            <div id="card-number" class="add-card-form__input"></div>
            <payment-method-form-error :error="cardElements.cardNumber.error" />
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-6">
        <payment-method-form-input
          id="name"
          v-model.trim="billingDetails.name"
          :error="errors.name"
          label="Name on card"
          @input="clearFormFieldError('name')"
        />
      </div>

      <div class="col-12 col-lg-6">
        <div class="add-card-form__input-container">
          <payment-method-form-label
            :label="cardElements.cardExpiry.name"
            :move-to-up="cardElements.cardExpiry.isNotEmpty"
          />
          <div class="d-block">
            <div id="card-expiry" class="add-card-form__input"></div>
            <payment-method-form-error :error="cardElements.cardExpiry.error" />
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-6">
        <div class="add-card-form__input-container">
          <payment-method-form-label :label="cardElements.cardCvc.name" :move-to-up="cardElements.cardCvc.isNotEmpty" />
          <div class="d-block">
            <div id="card-cvc" class="add-card-form__input"></div>
            <payment-method-form-error :error="cardElements.cardCvc.error" />
          </div>
        </div>
      </div>
    </div>

    <div class="row position-relative mt-4">
      <div class="col-12 col-lg-6">
        <payment-method-form-input
          id="addressLine1"
          v-model.trim="billingDetails.address.line1"
          :error="errors.addressLine1"
          label="Address line 1"
          @input="clearFormFieldError('addressLine1')"
        />
      </div>
      <div class="col-12 col-lg-6">
        <payment-method-form-input
          id="addressLine2"
          v-model.trim="billingDetails.address.line2"
          :error="errors.addressLine2"
          label="Address line 2 (optional)"
          @input="clearFormFieldError('addressLine2')"
        />
      </div>
      <div class="col-12 col-lg-6">
        <payment-method-form-input
          id="city"
          v-model.trim="billingDetails.address.city"
          :error="errors.city"
          label="City"
          @input="clearFormFieldError('city')"
        />
      </div>
      <div class="col-12 col-lg-6">
        <payment-method-form-input
          id="postalCode"
          v-model.trim="billingDetails.address.postal_code"
          :error="errors.postalCode"
          label="Zip/Postal code"
          @input="clearFormFieldError('postalCode')"
        />
      </div>
      <div class="col-12 col-lg-6">
        <payment-method-form-input
          id="state"
          v-model.trim="billingDetails.address.state"
          :error="errors.state"
          label="State/province (optional)"
          @input="clearFormFieldError('state')"
        />
      </div>

      <div class="col-12 col-lg-6">
        <payment-method-form-select
          id="select-country"
          v-model="selectedCountry"
          select-label="Country"
          :error="errors.country"
          :options="countries"
          group-label="group"
          group-values="countries"
          label="name"
          placeholder="Select from list"
          track-by="name"
          @input="onCountrySelect"
        />
      </div>
    </div>

    <div class="add-card-form__submit">
      <button type="submit" class="btn border-btn payment-details-form-button">Save</button>
      <payment-method-form-error class="add-card-form__submit-error" :error="errors.stripe" />
    </div>

    <div v-if="isFormRequestSending" class="add-card-form__loader-wrapper">
      <app-loader class="add-card-form__loader" />
    </div>
  </form>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import PaymentMethodFormLabel from '@/components/partials/profile/PaymentMethodFormLabel';
import PaymentMethodFormError from '@/components/partials/profile/PaymentMethodFormError';
import PaymentMethodFormInput from '@/components/partials/profile/PaymentMethodFormInput';
import PaymentMethodFormSelect from '@/components/partials/profile/PaymentMethodFormSelect';

import { getCountriesListByGroups } from '@/models/countriesList';
import createPaymentSetupIntentMutation from '@/graphql/me/payment/CreatePaymentSetupIntent.mutation.gql';

const STRIPE_ELEM_CARD_NUMBER_TYPE = 'cardNumber';
const STRIPE_ELEM_CARD_EXPIRY_TYPE = 'cardExpiry';
const STRIPE_ELEM_CARD_CVC_TYPE = 'cardCvc';

const stripeElementOptions = {
  placeholder: '',
  style: {
    base: {
      color: '#353535',
      fontSize: '20px',
      fontSmoothing: 'antialiased',
      fontWeight: 300,
    },
    invalid: {
      color: '#dc3545',
    },
  },
};

export default {
  name: 'AddNewPaymentMethodForm',
  components: { PaymentMethodFormSelect, PaymentMethodFormInput, PaymentMethodFormError, PaymentMethodFormLabel },
  props: {
    initUserData: {
      type: Object,
      default: () => ({}),
    },
    show: {
      type: Boolean,
      default: false,
    },
    stripePublicKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      cardElements: {
        cardNumber: {
          id: 'card-number',
          element: null,
          error: '',
          isNotEmpty: false,
          isValid: false,
          name: 'Card number',
        },
        cardExpiry: {
          id: 'card-expiry',
          element: null,
          error: '',
          isNotEmpty: false,
          isValid: false,
          name: 'Expiry date',
        },
        cardCvc: {
          id: 'card-cvc',
          element: null,
          error: '',
          isNotEmpty: false,
          isValid: false,
          name: 'CVV/CVC',
        },
      },
      billingDetails: {
        name: '',
        email: '',
        address: {
          country: '',
          city: '',
          line1: '',
          line2: '',
          postal_code: '',
          state: '',
        },
      },
      selectedCountry: null,
      countries: getCountriesListByGroups(),
      errors: {
        name: '',
        addressLine1: '',
        city: '',
        country: '',
        postalCode: '',
        state: '',
        stripe: '',
      },

      isFormRequestSending: false,
    };
  },
  _stripe: null,

  watch: {
    initUserData: {
      immediate: true,
      deep: true,
      handler(data) {
        this.billingDetails = { ...data.billingDetails };
        this.selectedCountry = { ...data.selectedCountry };
      },
    },
    show(showValue) {
      if (!showValue) {
        this.clearFormErrors();
        this.clearStripeElements();
      }
    },
    stripePublicKey: {
      immediate: true,
      async handler(publicKey) {
        if (!publicKey) {
          return;
        }
        await this.setupStripe(publicKey);
      },
    },
  },

  async mounted() {
    if (window.innerWidth < 768) {
      stripeElementOptions.style.base.fontSize = '16px';
    }
  },
  beforeDestroy() {
    this.destroyStripeElement(STRIPE_ELEM_CARD_NUMBER_TYPE);
    this.destroyStripeElement(STRIPE_ELEM_CARD_EXPIRY_TYPE);
    this.destroyStripeElement(STRIPE_ELEM_CARD_CVC_TYPE);
    this._stripe = null;
  },

  methods: {
    async setupStripe(token) {
      this._stripe = await loadStripe(token);
      if (!this._stripe) {
        return;
      }
      const elements = this._stripe.elements();

      this.cardElements.cardNumber.element = elements.create(STRIPE_ELEM_CARD_NUMBER_TYPE, stripeElementOptions);
      this.cardElements.cardExpiry.element = elements.create(STRIPE_ELEM_CARD_EXPIRY_TYPE, stripeElementOptions);
      this.cardElements.cardCvc.element = elements.create(STRIPE_ELEM_CARD_CVC_TYPE, stripeElementOptions);

      this.mountStripeElement(STRIPE_ELEM_CARD_NUMBER_TYPE);
      this.mountStripeElement(STRIPE_ELEM_CARD_EXPIRY_TYPE);
      this.mountStripeElement(STRIPE_ELEM_CARD_CVC_TYPE);

      this.stripeElementChangeHandler(STRIPE_ELEM_CARD_NUMBER_TYPE);
      this.stripeElementChangeHandler(STRIPE_ELEM_CARD_EXPIRY_TYPE);
      this.stripeElementChangeHandler(STRIPE_ELEM_CARD_CVC_TYPE);
    },
    mountStripeElement(name) {
      const instance = this.cardElements[name];
      if (instance && instance.element) {
        instance.element.mount(`#${instance.id}`);
      }
    },
    destroyStripeElement(name) {
      this.cardElements?.[name]?.element?.destroy();
    },
    stripeElementChangeHandler(name) {
      const instance = this.cardElements?.[name];
      if (!instance) {
        return;
      }
      instance.element.on('change', (element) => {
        instance.error = element.error?.message || '';
        instance.isValid = !!element.complete;
        instance.isNotEmpty = !element.empty;
      });
    },

    onCountrySelect() {
      this.errors.country = '';
      this.billingDetails.address.country = this.selectedCountry.code;
    },

    clearFormFieldError(field) {
      this.errors[field] = '';
    },
    clearFormErrors() {
      this.cardElements.cardNumber.error = '';
      this.cardElements.cardExpiry.error = '';
      this.cardElements.cardCvc.error = '';
      this.errors.stripe = '';

      for (const field in this.errors) {
        this.errors[field] = '';
      }
    },

    async submitFormToCreatePaymentMethod() {
      if (this.isFormRequestSending) {
        return;
      }

      this.clearFormErrors();
      if (!this.validateForm()) {
        return;
      }

      this.isFormRequestSending = true;
      const { client_secret: clientSecret } = await this.createPaymentSetupIntent();
      const paymentMethodId = await this.createPaymentMethod(clientSecret);

      this.$emit('added', paymentMethodId);
      this.isFormRequestSending = false;
    },

    async createPaymentSetupIntent() {
      const result = await this.$apollo.mutate({
        mutation: createPaymentSetupIntentMutation,
      });

      const setupIntent = result?.data?.createPaymentSetupIntent;
      if (!setupIntent || !setupIntent.client_secret) {
        return {};
      }
      return setupIntent;
    },
    async createPaymentMethod(clientSecret) {
      if (!clientSecret) {
        return null;
      }
      const { setupIntent, error } = await this._stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: this.cardElements.cardNumber.element,
          billing_details: this.billingDetails,
        },
      });

      if (error) {
        this.showStripeError(error.message);
        return null;
      }
      return setupIntent.payment_method;
    },

    clearStripeElements() {
      this.clearStripeElement(STRIPE_ELEM_CARD_NUMBER_TYPE);
      this.clearStripeElement(STRIPE_ELEM_CARD_EXPIRY_TYPE);
      this.clearStripeElement(STRIPE_ELEM_CARD_CVC_TYPE);
    },
    clearStripeElement(name) {
      const instance = this.cardElements[name];
      if (instance && instance.element) {
        instance.element.clear();
        instance.empty = true;
        instance.error = '';
      }
    },

    validateForm() {
      let isValid = true;

      if (this.errors.stripe) {
        isValid = false;
      }

      for (const type in this.cardElements) {
        if (!this.cardElements[type].isValid) {
          isValid = false;
          this.setElementError(type);
        }
      }

      if (!this.billingDetails.name) {
        isValid = false;
        this.errors.name = 'Name on card is required';
      }
      if (!this.billingDetails.address.line1) {
        isValid = false;
        this.errors.addressLine1 = 'Address line 1 is required';
      }
      if (!this.billingDetails.address.city) {
        isValid = false;
        this.errors.city = 'City is required';
      }
      if (!this.billingDetails.address.postal_code) {
        isValid = false;
        this.errors.postalCode = 'Zip/postal code is required';
      }
      if (!this.billingDetails.address.country) {
        isValid = false;
        this.errors.country = 'Country is required';
      }

      return isValid;
    },
    setElementError(type) {
      const element = this.cardElements[type];
      const message = element.isNotEmpty ? 'incomplete' : 'required';
      this.cardElements[type].error = `${element.name} is ${message}`;
    },
    showStripeError(err) {
      this.errors.stripe = err;
      setTimeout(() => {
        this.errors.stripe = '';
      }, 3000);
    },
  },
};
</script>
